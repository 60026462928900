// Core variables and mixins
@import 'spectre/variables';
@import 'spectre/mixins';

@import 'theme/mixins';
@import 'theme/framework';

// Core Styling
@import 'theme/style/alerts';
@import 'theme/style/typography';
@import 'theme/style/buttons';
@import 'theme/style/utilities';

// Sections Styling
@import 'theme/sections/offsidebar';
@import 'theme/sections/header';
@import 'theme/sections/navigation';
@import "theme/sections/maincontent";
@import 'theme/sections/slideshow';
@import 'theme/sections/showcase';
@import 'theme/sections/features';
@import 'theme/sections/features1';
@import 'theme/sections/features2';
@import 'theme/sections/workshops';
@import 'theme/sections/workshops1';
@import 'theme/sections/workshops2';
@import 'theme/sections/workshops3';
@import 'theme/sections/workshops4';
@import 'theme/sections/workshops5';
@import 'theme/sections/bank';
@import 'theme/sections/textcase';
@import 'theme/sections/textcase1';
@import 'theme/sections/textcase2';
@import 'theme/sections/textcase3';
@import 'theme/sections/textcase4';
@import 'theme/sections/textcase5';
@import 'theme/sections/kurs11';
@import 'theme/sections/kurs12';
@import 'theme/sections/kurs13';
@import 'theme/sections/kurs14';
@import 'theme/sections/kurs21';
@import 'theme/sections/kurs22';
@import 'theme/sections/kurs23';
@import 'theme/sections/kurs24';
@import 'theme/sections/kurs31';
@import 'theme/sections/kurs32';
@import 'theme/sections/kurs33';
@import 'theme/sections/kurs34';
@import 'theme/sections/testimonials';
@import 'theme/sections/news';
@import 'theme/sections/sponsors';
@import 'theme/sections/footer';
@import 'theme/sections/newsletter';
@import 'theme/sections/video';

// Partials Styling
@import "theme/partials/pageheader";
@import "theme/partials/blog_item";
@import "theme/partials/courses";
@import "theme/partials/contact";

// Elements Styling
@import 'theme/elements/menu';
@import 'theme/elements/logo';
@import 'theme/elements/calendar';

// Modular
@import "theme/modular/owlcarousel";
@import "theme/modular/blocks";
@import "theme/modular/news";
@import "theme/modular/testimonials";
@import "theme/modular/sponsors";
@import "theme/modular/infocols";
