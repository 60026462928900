.list-blog-item {
    padding: 2rem;

    .list-item {
        .list-item-video {
            padding-bottom: 50%;

            iframe {
                max-height: 300px;
            }
        }
    }
}

.list-item {
    .list-item-image {
        margin: 2rem 0;

        img {
            max-width: 100%;
        }
    }

    .list-item-video {
        position: relative;
        margin: 2rem 0;
        padding-bottom: 56.25%; /* 16:9 */
        height: 0;
        width: 100%;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    .list-item-meta {
        margin-bottom: 1.5rem;

        > div {
            display: inline-block;
            margin-right: 0.75rem;
        }
    }
}
