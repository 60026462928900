.blocks-container {
    margin: 6rem 0;

    .single-block {
        padding: 1.75rem;

        .block-wrapper {
            position: relative;
            overflow: hidden;
            cursor: pointer;

            .block-content-link {
                position: absolute;
                z-index: 10;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                display: block;
                content: '';
                width: 100%;
                height: 100%;
            }

            .block-image-wrapper {
                img {
                    width: 100%;
                    height: auto;
                    max-width: 100%;
                    display: inline-block;
                    vertical-align: middle;
                    border: 0;
                    -ms-interpolation-mode: bicubic;
                }
            }

            .block-content-wrapper {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
                width: 100%;
                height: 100%;
                text-align: left;

                .block-content-title {
                    position: absolute;
                    bottom: 0;
                    padding: 3.5rem;
                    color: #ffffff;
                    line-height: 0.8;
                    font-weight: 900;
                    transform: translateY(0);
                    transition: all 0.2s ease-in-out;
                    @include responsive-font($responsive-html-font-size + 1.2, 50px, false, false);
                }

                .block-content-description {
                    visibility: hidden;
                    opacity: 0;
                    padding: 1.4rem;
                    background: #277397;
                    color: transparentize(#ffffff, 0.35);
                    font-size: $font-size + 0.2;
                    transform: translateY(100%);
                    transition: all 0.2s ease-in-out;
                }
            }

            &:hover {
                .block-content-title {
                    transform: translateY(-50%);
                }

                .block-content-description {
                    opacity: 1;
                    visibility: visible;
                    transform: translateY(0);
                }
            }
        }
    }
}
