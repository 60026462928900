#news {
    @extend .section-horizontal-padding;
    background: #090909;
    color: #fff;

    h5 {
        margin-bottom: 3rem;
        font-weight: 600;
    }
}
