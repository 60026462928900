// Core variables
$dark-color: #14181e;
$light-color: #ffffff;

// Global
$html-font-size: 10px;
$responsive-html-font-size: 1vw;
$html-line-height: 1.428571429;
$body-bg: $light-color;
$body-font-color: #666666;
$font-size: 1.4rem;
$font-size-sm: 1.2rem;
$font-size-lg: 1.6rem;

// Fonts
// Credit: https://www.smashingmagazine.com/2015/11/using-system-ui-fonts-practical-guide/
$base-font-family: "Raleway", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto;
$fallback-font-family: "Helvetica Neue", sans-serif;
$cjk-font-family: $base-font-family, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Hiragino Kaku Gothic Pro", Meiryo, "Malgun Gothic", $fallback-font-family;
$body-font-family: $base-font-family, $fallback-font-family;

// Sizes
$layout-padding: 0;
$layout-padding-lg: 1.5rem;
$control-min-width: 18rem;
$border-radius: .2rem;
$border-width: .1rem;

// Colors
// Core colors
$primary-color: #d00014;
$primary-color-dark: darken($primary-color, 3%);
$primary-color-light: lighten($primary-color, 3%);
$secondary-color: lighten($primary-color, 40%);
$secondary-color-dark: darken($secondary-color, 3%);
$secondary-color-light: lighten($secondary-color, 3%);

$link-color: $primary-color;
$link-color-dark: darken($link-color, 10%);

// Gray colors
$gray-color: lighten($dark-color, 40%);
$gray-color-dark: darken($gray-color, 20%);
$gray-color-light: lighten($gray-color, 20%);

$border-color: lighten($gray-color-light, 3%);
$border-color-dark: darken($border-color, 15%);
$bg-color: lighten($dark-color, 66%);
$bg-color-dark: darken($bg-color, 3%);
$bg-color-light: $light-color;

// Control colors
$control-color-success: #32b643;
$control-color-warning: #ffb700;
$control-color-error: #e85600;

// Other colors
$code-color: #e06870;
$highlight-color: #ffe9b3;

// Responsive breakpoints
$size-xs: 480px;
$size-sm: 600px;
$size-md: 840px;
$size-lg: 960px;
$size-xl: 1280px;
$size-2x: 1440px;

// Z-index
$zindex-0: 1;
$zindex-1: 100;
$zindex-2: 200;
$zindex-3: 300;
$zindex-4: 400;
