form#contact {
    .form-label {
        padding: 1.5rem 0;
    }

    .form-field {
        .form-input-wrapper, .form-textarea-wrapper {
            input, textarea {
                width: 75%;
                padding: 1rem 1.5rem;
                border: 1px solid $border-color;

                @include breakpoint(md) {
                    width: 100%;
                }
            }
        }
    }

    .buttons {
        margin-top: 3rem;

        button {
            &:hover {
                border: 1px solid $primary-color;
            }
        }
    }
}
