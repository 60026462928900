body.page-video {
    background: #000000;

    // Page with Videos listed
    &.page-video-listing {
        .container {
            padding-top: 3rem;

            h3 {
                margin-bottom: 3rem;
                color: #ffffff;
                text-align: center;
            }

            .two-columns {
                padding-top: 1rem;
            }

            // Video Container
            #video-container {
                margin: 1rem;
                padding: 0 10%;
                background: #2D2D2D;

                @include breakpoint(md) {
                    padding: 0;
                }

                .video-iframe-wrapper {
                    position: relative;
                    padding-bottom: 56.25%; /* 16:9 */
                    height: 0;

                    iframe {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                    }
                }
            }

            // Video Item Wrapper
            .video-items-wrapper {
                display: flex;
                flex-wrap: wrap;

                .video-item {
                    flex: 0 0 25%;

                    @include breakpoint(md) {
                        flex: 0 0 100%;
                    }
                }
            }

            // Video Block
            .video-block {
                position: relative;
                margin: 1rem;
                background: #2D2D2D;
                min-height: 125px;
                overflow: hidden;

                a {
                    position: absolute;
                    display: block;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    width: 100%;
                    height: 100%;
                    padding: 1.5rem 3rem;
                    font-size: 2.3rem;

                    @include breakpoint(lg) {
                        font-size: 1.75rem;
                    }

                    @include breakpoint(md) {
                        font-size: 2.3rem;
                    }

                    &:hover {
                        background: #ffffff;
                    }
                }
            }
        }
    }

    // Login Page
    &.page-video-login {
        &:before {
            position: fixed;
            z-index: 5;
            content: '';
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(0, 0, 0, 0.9);
        }

        .container {

            h1 {
                position: relative;
                z-index: 6;
                margin-top: 2.5rem;
                color: #ffffff;
                text-align: center;
                letter-spacing: 3px;
            }

            #grav-login {
                position: relative;
                z-index: 6;
                background: none;
                border: none;
                border-radius: 0;

                .alert {
                    margin-bottom: 2.5rem;
                }

                h1 {
                    display: none;
                }

                form {
                    .form-actions {
                        display: none;
                    }

                    .form-field {
                        .input {
                            padding: 0.5rem 1.5rem;
                            border: 0;
                            background: darken($body-font-color, 15%);
                            color: lighten($body-font-color, 5%);
                        }
                    }
                }
            }
        }
    }
}
