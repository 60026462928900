#bank {
    @extend .section-vertical-padding;
    @extend .section-horizontal-padding;
    position: relative;
    background: #ff0e6e;
    color: #fff;

    &.parallax-enabled {
        background: transparent;
    }

    a {
        color: lighten(#ff0e6e, 30%);

        &:hover {
            color: lighten(#ff0e6e, 40%);
        }
    }

    .container {
        position: relative;
        font-size: $font-size + 1;
        font-weight: 300;

        h1 {
            margin-bottom: 2.5rem;
        }

        p {
            line-height: inherit;
        }

        .button {
            margin-top: 2rem;
        }
    }
}
