//-------------------------------------------//

// OWL CAROUSEL ORIGINAL STYLING

/*
*  Owl Carousel - Animate Plugin
*/
.owl-carousel .animated {
    -webkit-animation-duration: 1000ms;
    animation-duration: 1000ms;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
    z-index: 0;
}

.owl-carousel .owl-animated-out {
    z-index: 1;
}

.owl-carousel .fadeOut {
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut;
}

@-webkit-keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

/*
*  Owl Carousel - Auto Height Plugin
*/
.owl-height {
    -webkit-transition: height 500ms ease-in-out;
    -moz-transition: height 500ms ease-in-out;
    -ms-transition: height 500ms ease-in-out;
    -o-transition: height 500ms ease-in-out;
    transition: height 500ms ease-in-out;
}

/*
*  Core Owl Carousel CSS File
*/
.owl-carousel {
    display: none;
    width: 100%;
    -webkit-tap-highlight-color: transparent;
    /* position relative and z-index fix webkit rendering fonts issue */
    position: relative;
    z-index: 1;
}

.owl-carousel .owl-stage {
    position: relative;
    -ms-touch-action: pan-Y;
}

.owl-carousel .owl-stage:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
}

.owl-carousel .owl-stage-outer {
    position: relative;
    overflow: hidden;
    /* fix for flashing background */
    -webkit-transform: translate3d(0px, 0px, 0px);
}

.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
    cursor: pointer;
    cursor: hand;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.owl-carousel.owl-loaded {
    display: block;
}

.owl-carousel.owl-loading {
    opacity: 0;
    display: block;
}

.owl-carousel.owl-hidden {
    opacity: 0;
}

.owl-carousel .owl-refresh .owl-item {
    display: none;
}

.owl-carousel .owl-item {
    position: relative;
    min-height: 1px;
    float: left;
    width: 100%;
    -webkit-backface-visibility: hidden;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transform: translate3d(0, 0, 0) !important;
}

.owl-carousel .owl-item img {
    display: block;
    width: 100%;
    -webkit-transform-style: preserve-3d;
}

.owl-carousel.owl-text-select-on .owl-item {
    -webkit-user-select: auto;
    -moz-user-select: auto;
    -ms-user-select: auto;
    user-select: auto;
}

.owl-carousel .owl-grab {
    cursor: move;
    cursor: -webkit-grab;
    cursor: -o-grab;
    cursor: -ms-grab;
    cursor: grab;
}

.owl-carousel.owl-rtl {
    direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
    float: right;
}

/* No Js */
.no-js .owl-carousel {
    display: block;
}

/*
*  Owl Carousel - Lazy Load Plugin
*/
.owl-carousel .owl-item .owl-lazy {
    opacity: 0;
    -webkit-transition: opacity 400ms ease;
    -moz-transition: opacity 400ms ease;
    -ms-transition: opacity 400ms ease;
    -o-transition: opacity 400ms ease;
    transition: opacity 400ms ease;
}

.owl-carousel .owl-item img {
    transform-style: preserve-3d;
}

/*
*  Owl Carousel - Video Plugin
*/
.owl-carousel .owl-video-wrapper {
    position: relative;
    height: 100%;
    background: #000;
}

.owl-carousel .owl-video-play-icon, .owl-carousel .owl-videolocal-play-icon {
    position: absolute;
    height: 66px;
    width: 66px;
    left: 50%;
    bottom: 15%;
    margin-left: -40px;
    margin-top: -40px;
    background: url('../images/owlcarousel/play.svg') no-repeat;
    background-size: contain;
    cursor: pointer;
    z-index: 10;
    -webkit-backface-visibility: hidden;
    opacity: 1;
    transition: all 0.25s ease-in-out;

    &.paused {
        background: url('../images/owlcarousel/paused.svg');
        background-size: contain;
        opacity: 0.8;
    }
}

.owl-carousel .owl-video-playing {
    &:hover {
        .owl-video-play-icon.paused {
            display: block;
        }
    }
}

.owl-carousel .owl-video-play-icon:hover {
    -webkit-transform: scale(1.3);
    -moz-transform: scale(1.3);
    -ms-transform: scale(1.3);
    -o-transform: scale(1.3);
    transform: scale(1.3);
}

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
    display: none;
}

.owl-carousel .owl-video-tn {
    opacity: 0;
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    -webkit-transition: opacity 400ms ease;
    -moz-transition: opacity 400ms ease;
    -ms-transition: opacity 400ms ease;
    -o-transition: opacity 400ms ease;
    transition: opacity 400ms ease;
}

.owl-carousel .owl-video-frame {
    position: relative;
    z-index: 1;
}

/*
*  Owl Carousel - Nav & Dots
*/
.owl-nav {
    opacity: 1;

    div {
        position: absolute;
        margin: auto;
        bottom: 0;
        top: 0;
        left: 0;
        right: 0;
        opacity: 1;
        width: 40px;
        height: 40px;
        display: inline-block;
        color: #fff;
        text-align: center;
        text-transform: uppercase;
        font-size: ($html-font-size * 3);
        font-weight: 600;

        &:hover {
            color: $primary-color;
        }

        &.owl-prev {
            left: 4rem;
            right: auto;
        }

        &.owl-next {
            left: auto;
            right: 4rem;
        }
    }

    &.disabled {
        display: none;
    }
}

.owl-dots {
    margin: 1rem 0;
    text-align: center;

    .owl-dot {
        display: inline-block;
        background: transparentize($primary-color, 0.75);
        width: 15px;
        height: 15px;
        border-radius: 100%;
        margin: 0 0.6rem;
        vertical-align: middle;

        &.active {
            background: $primary-color;
        }
    }
}

//-------------------------------------------//
// Theme Specific Styling
//-------------------------------------------//

.owl-carousel {
    .owl-item {
        overflow: hidden;

        .owl-video-wrapper {
            .owl-video-frame {
                height: 100%;

                iframe {
                    height: 140%;
                    margin-top: -8%;
                }
            }
        }

        .owl-carousel-slide-content {
            display: flex;
            position: absolute;
            z-index: 5;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            @include breakpoint(md) {
                padding-top: 5rem;
            }

            .owl-carousel-slide-description {
                font-style: italic;
                @include responsive-font($responsive-html-font-size + 0.85, 25px, false, false);

                @include breakpoint(md) {
                    text-align: center;
                }
            }

            .owl-carousel-slide-title {
                font-weight: 300;
                line-height: 1.2;
                @include responsive-font($responsive-html-font-size + 4.5, 35px, false, false);

                @include breakpoint(md) {
                    margin-top: 1rem;
                    text-align: center;
                }
            }

            .owl-carousel-slide-caption {
                font-weight: 700;
                letter-spacing: 3px;
                @include responsive-font($responsive-html-font-size + 0.5, 20px, false, false);

                @include breakpoint(md) {
                    text-align: center;
                    margin-top: 2rem;
                }
            }

            .button {
                position: relative;
                margin-top: ($layout-padding-lg * 2);
            }
        }

        // Mobile Fixes
        @include breakpoint(md) {
            .owl-carousel-image {
                img {
                    width: auto;
                    max-width: none;
                    max-height: 50rem;
                }
            }

            .owl-carousel-slide-position-center {
                .owl-carousel-image {
                    img {
                        transform: translateX(-35%);
                    }
                }
            }

            .owl-carousel-slide-position-right {
                .owl-carousel-image {
                    img {
                        transform: translateX(-70%);
                    }
                }
            }
        }
    }
}
