#offsidebar-nav {
    position: fixed;
    z-index: 100001;
    top: 0;
    width: 24rem;
    opacity: 0;
    visibility: hidden;
    background: #000000;
    color: $gray-color-light;
    box-shadow: 5px -10px 10px rgba(0, 0, 0, 0.25);
    transform: translateY(-100%);
    transition: all 0.25s;

    // Mobile
    @include breakpoint(sm) {
        height: 100%;
        transform: translateY(0);
        transform: translateX(-100%);
    }

    &:after {
        position: absolute;
        content: "";
        height: 0;
        top: 100%;
        width: 100%;
        background: linear-gradient(to bottom, #000000, rgba(0, 0, 0, 0.00));
        box-shadow: 0 -20px 0 #000000, 5px -10px 10px rgba(0, 0, 0, 0.25);

        // Mobile
        @include breakpoint(sm) {
            display: none;
            visibility: hidden;
            opacity: 0;
        }
    }

    &.offsidebar-open {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);

        &:after {
            height: 10rem;
        }

        // Mobile
        @include breakpoint(sm) {
            transform: translateX(0);
        }
    }
}

#offsidebar-overlay {
    cursor: pointer;
    opacity: 0;
    background: rgba(0, 0, 0, 0.25);
    bottom: 0;
    content: "";
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: -1;
    transition: all 0.25s;
}

#navigation-toggles {
    position: absolute;
    top: 0.5rem;
    left: 2rem;
    color: #ffffff;
    font-size: ($font-size + 2);
    transition: all 0.25s;

    // Mobile
    @include breakpoint(sm) {
        z-index: 100001;
        transform: translateX(0);

        body.navigation-open & {
            transform: translateX(28rem);
        }
    }

    .fa {
        &:hover {
            cursor: pointer;
        }
    }
}
