.logo {
    margin-top: 12px;
    text-indent: -9999px;

    .navbar-brand {
        display: block;
        width: 170px;
        height: 35px;
        background-size: contain;
        background: url('../images/logo/logo.svg') no-repeat;
    }
}
