.news-items {
    .news-items-left {
        display: flex;
        flex-flow: wrap;
        flex-direction: column;

        .news-item {
            display: block;
            width: 50%;
            background-size: cover;
            background-repeat: no-repeat;
            color: #ffffff;

            @include breakpoint(sm) {
                width: 100%;
            }

            .news-item-wrapper {
                display: block;
                width: 100%;
                height: 100%;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: cover;

                .news-item-content {
                    display: block;
                    height: 100%;
                    padding: 3.25rem;
                    transition: background 0.3s;

                    &:hover {
                        background: transparentize($primary-color, 0.75);
                    }

                    .news-item-date {
                        display: inline-block;
                        margin-top: 2rem;
                        padding: 0.35rem 0.65rem;
                        background: $primary-color;
                        border-radius: 5px;
                    }

                    .news-item-title {
                        display: block;
                        margin: 2rem 0 1.5rem;
                        font-size: $font-size + 0.25;
                        font-weight: 600;
                        text-transform: uppercase;

                        a {
                            color: #ffffff;
                        }
                    }
                }
            }
        }
    }

    .news-items-right {
        @extend .section-vertical-padding-small;
        padding-left: 5%;

        .news-item {
            margin: 0.5rem 0;
            padding: 0.75rem 0 1rem;

            &:not(:last-child) {
                border-bottom: 1px solid transparentize($body-font-color, 0.5);
            }

            .news-item-title {
                font-size: $font-size + 0.25;

                a {
                    color: #ffffff;

                    &:hover {
                        color: transparentize(#ffffff, 0.25);
                    }
                }
            }

            .news-item-authors {
                color: $body-font-color;

                p {
                    margin: 0;
                }

                a {
                    color: $body-font-color;

                    &:hover {
                        color: transparentize($body-font-color, 0.25);
                    }
                }
            }
        }
    }
}
