#newsletter {
    @extend .section-vertical-padding-small;
    @extend .section-horizontal-padding;
    background: #15181e;
    color: #fff;

    // MailChimp Form
    form#newsletter {
        position: relative;
        margin: 3.5rem 0;
        padding: 0;
        font-size: $font-size;

        .form-field {
            position: relative;
            display: inline-block;
            width: 33.3%;

            @include breakpoint(md) {
                width: 66.6%;
            }

            @include breakpoint(sm) {
                width: 100%;
            }

            .form-label {
                display: none;
            }

            .form-input-wrapper {
                input {
                    width: 100%;
                    padding: 1.5rem 2rem;
                    border-radius: 6rem;
                    border: 0;

                    &:focus {
                        outline: 0;
                    }
                }
            }
        }

        .buttons {
            position: relative;
            display: inline-block;
            margin-left: -124px;

            @include breakpoint(sm) {
                margin-top: 10px;
                margin-left: 0px;
                width: 100%;
            }

            .button {
                padding: 1.1rem 2.5rem;
                vertical-align: baseline;
                font-size: 14px;

                @include breakpoint(sm) {
                    width: 100%;
                }

                &:hover {
                    color: #ffffff;
                    background: $link-color-dark;
                }
            }
        }
    }

    .newsletter-social {
        display: block;
        margin: 2rem 0;

        .newsletter-social-item {
            display: inline-block;
            margin: 0 5px;
            padding-top: 8px;
            width: 35px;
            height: 35px;
            background: #ffffff;
            border-radius: 50%;

            &:hover {
                background: $primary-color;

                a {
                    color: #ffffff;
                }
            }

            a {
                .fa {
                    font-size: 20px;

                    &.fa-facebook {
                        margin-left: -2px;
                    }
                }
            }
        }
    }
}
