/*!
 * FullCalendar v2.4.0 Stylesheet
 * Docs & License: http://fullcalendar.io/
 * (c) 2015 Adam Shaw
 */

$brand-primary: $primary-color;

.fc {
    direction: ltr;
    text-align: left;
    padding: 2rem;
}

.fc-rtl {
    text-align: right;
}

body .fc { /* extra precedence to overcome jqui */
    font-size: 1em;
}

/* Colors
--------------------------------------------------------------------------------------------------*/

.fc-unthemed th,
.fc-unthemed td,
.fc-unthemed thead,
.fc-unthemed tbody,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-row,
.fc-unthemed .fc-popover,
.fc-unthemed .fc-list-table {
    border-color: $border-color;
}

.fc-unthemed .fc-popover {
    background-color: #fff;
}

.fc-unthemed .fc-divider,
.fc-unthemed .fc-popover .fc-header {
    background: #eee;
}

.fc-unthemed .fc-popover .fc-header .fc-close {
    color: #666;
}

.fc-unthemed .fc-today {
    background: #fcf8e3;
}

.fc-highlight { /* when user is selecting cells */
    background: #bce8f1;
    opacity: .3;
    filter: alpha(opacity=30); /* for IE */
}

.fc-bgevent { /* default look for background events */
    background: rgb(143, 223, 130);
    opacity: .3;
    filter: alpha(opacity=30); /* for IE */
}

.fc-nonbusiness { /* default look for non-business-hours areas */
    /* will inherit .fc-bgevent's styles */
    background: #d7d7d7;
}


/* Icons (inline elements with styled text that mock arrow icons)
--------------------------------------------------------------------------------------------------*/

.fc-icon {
    font-family: "FontAwesome";
}

.fc-icon:after {
    position: relative;
}

.fc-icon-left-single-arrow:after {
    content: "\f104";
    font-weight: bold;
}

.fc-icon-right-single-arrow:after {
    content: "\f105";
    font-weight: bold;
}

.fc-icon-left-double-arrow:after {
    content: "\f100";
}

.fc-icon-right-double-arrow:after {
    content: "\f101";
}

.fc-icon-left-triangle:after {
    content: "\25C4";
    font-size: 125%;
    top: 3%;
    left: -2%;
}

.fc-icon-right-triangle:after {
    content: "\25BA";
    font-size: 125%;
    top: 3%;
    left: 2%;
}

.fc-icon-down-triangle:after {
    content: "\25BC";
    font-size: 125%;
    top: 2%;
}

.fc-icon-x:after {
    content: "\000D7";
    font-size: 200%;
    top: 6%;
}


/* Buttons (styled <button> tags, normalized to work cross-browser)
--------------------------------------------------------------------------------------------------*/

.fc button {
    //@extend .btn;
    //@extend .btn-secondary;
}

/* Firefox has an annoying inner border */
.fc button::-moz-focus-inner { margin: 0; padding: 0; }

.fc-state-default { /* non-theme */
    border: 1px solid;
}

/* icons in buttons */

.fc button .fc-icon { /* non-theme */
    position: relative;
    top: -0.05em; /* seems to be a good adjustment across browsers */
    margin: 0 .2em;
    vertical-align: middle;
}

.fc-today-button {
    @extend %button;
}

.fc-prev-button,
.fc-next-button {
    border: none;
    background: none;
    @include responsive-font($responsive-html-font-size, 16px, false, false);

    &:hover {
        cursor: pointer;
        background: none;
        color: $primary-color;
    }

    &:focus {
        outline: none;
    }
}

.fc-state-hover,
.fc-state-down,
.fc-state-active,
.fc-state-disabled {
    color: #ffffff;
    background-color: $primary-color;
}

.fc-state-hover {
    color: #333333;
    text-decoration: none;
    background-position: 0 -15px;
    -webkit-transition: background-position 0.1s linear;
    -moz-transition: background-position 0.1s linear;
    -o-transition: background-position 0.1s linear;
    transition: background-position 0.1s linear;
}

.fc-state-active {
    background-color: #cccccc;
    background-image: none;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
}

.fc-state-disabled {
    cursor: not-allowed;
    background-image: none;
    opacity: 0.45;
    filter: alpha(opacity=45);
    box-shadow: none;
}

/* Popover
--------------------------------------------------------------------------------------------------*/

.fc-popover {
    position: absolute;
    box-shadow: 0 2px 6px rgba(0,0,0,.15);
}

.fc-popover .fc-header { /* TODO: be more consistent with fc-head/fc-body */
    padding: 2px 4px;
}

.fc-popover .fc-header .fc-title {
    margin: 0 2px;
}

.fc-popover .fc-header .fc-close {
    cursor: pointer;
}

.fc-ltr .fc-popover .fc-header .fc-title,
.fc-rtl .fc-popover .fc-header .fc-close {
    float: left;
}

.fc-rtl .fc-popover .fc-header .fc-title,
.fc-ltr .fc-popover .fc-header .fc-close {
    float: right;
}

/* unthemed */

.fc-unthemed .fc-popover {
    border-width: 1px;
    border-style: solid;
}

.fc-unthemed .fc-popover .fc-header .fc-close {
    font-size: .9em;
    margin-top: 2px;
}

/* jqui themed */

.fc-popover > .ui-widget-header + .ui-widget-content {
    border-top: 0; /* where they meet, let the header have the border */
}


/* Misc Reusable Components
--------------------------------------------------------------------------------------------------*/

.fc-divider {
    border-style: solid;
    border-width: 1px;
}

hr.fc-divider {
    height: 0;
    margin: 0;
    padding: 0 0 2px; /* height is unreliable across browsers, so use padding */
    border-width: 1px 0;
}

.fc-clear {
    clear: both;
}

.fc-bg,
.fc-bgevent-skeleton,
.fc-highlight-skeleton,
.fc-helper-skeleton {
    /* these element should always cling to top-left/right corners */
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}

.fc-bg {
    bottom: 0; /* strech bg to bottom edge */
}

.fc-bg table {
    height: 100%; /* strech bg to bottom edge */
}


/* Tables
--------------------------------------------------------------------------------------------------*/

.fc table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    border-spacing: 0;
    font-size: 1em; /* normalize cross-browser */
}

.fc th {
    text-align: center;
}

.fc th,
.fc td {
    border-style: solid;
    border-width: 1px;
    padding: 1.5rem;
    vertical-align: top;
}

.fc td.fc-today {
    border-style: double; /* overcome neighboring borders */
}

.fc .fc-list-table {
    table-layout: auto;
    border-width: 1px;
    border-style: solid;

    tr {
        td {
            border-width: 1px 0 0;
        }

        &:first-child {
            td {
                border-top-width: 0;
            }
        }
    }
}

.fc .fc-list-heading {
    border-bottom-width: 1px;
    background: transparentize($border-color, 0.8);

    .fc-widget-header {
        font-weight: 600;

        .fc-list-heading-main {
            &:after {
                content: ',';
            }
        }

        .fc-list-heading-alt {
            margin-left: 5px;
        }
    }
}

/* Scroller
--------------------------------------------------------------------------------------------------*/
.fc-scroller {
    height: auto !important;
}

/* Toolbar
--------------------------------------------------------------------------------------------------*/

.fc-toolbar {
    text-align: center;
    margin-bottom: 1em;
}

.fc-toolbar .fc-left {
    float: left;
}

.fc-toolbar .fc-right {
    float: right;
}

.fc-toolbar .fc-center {
    display: inline-block;
}

/* the things within each left/right/center section */
.fc .fc-toolbar > * > * { /* extra precedence to override button border margins */
    float: left;
    margin-left: .75em;
}

/* the first thing within each left/center/right section */
.fc .fc-toolbar > * > :first-child { /* extra precedence to override button border margins */
    margin-left: 0;
}

/* title text */

.fc-toolbar h2 {
    margin: 0;
}

/* button layering (for border precedence) */

.fc-toolbar button {
    position: relative;
}

.fc-toolbar .fc-state-hover,
.fc-toolbar .ui-state-hover {
    z-index: 2;
}

.fc-toolbar .fc-state-down {
    z-index: 3;
}

.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active {
    z-index: 4;
}

.fc-toolbar button:focus {
    z-index: 5;
}
