#slideshow {
    .container {
        margin-bottom: -10px;
        max-width: 100%;

        // Owl Carousel Overlay
        .owl-carousel {
            color: #ffffff;

            .owl-item {
                .owl-carousel-image,
                .owl-carousel-video {
                    &:after {
                        position: absolute;
                        content: '';
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: transparent url("../images/overlays/01.png") center center repeat;
                        opacity: 0.5;
                    }
                }

                .owl-carousel-video {
                    &:after {
                        z-index: 5;
                    }
                }
            }
        }
    }
}
