#showcase {
    @extend .section-vertical-padding;
    @extend .section-horizontal-padding;
    position: relative;
    background-position: 0 45%;
    background-repeat: no-repeat;
    background-size: cover;
    font-size: $font-size + 1;

    //&:before {
    //    position: absolute;
    //    top: 0;
    //    left: 0;
    //    display: block;
    //    content: '';
    //    width: 100%;
    //    height: 100%;
    //    background: linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.8) 25%);
    //}

    .container {
        position: relative;

        p {
            @include responsive-font($responsive-html-font-size, 16px, false, false);
            line-height: 1.8;
        }
    }
}
