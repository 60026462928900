.overlay-pink {
    &:before {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        content: '';
        width: 100%;
        height: 100%;
        background: rgba(255, 14, 110, 0.6);
    }
}

.overlay-0 {
    &:before {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        content: '';
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.0);
    }
}

.overlay-blue {
    &:before {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        content: '';
        width: 100%;
        height: 100%;
        background: rgba(39, 115, 151, 0.6);
    }
}

.overlay-green {
    &:before {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        content: '';
        width: 100%;
        height: 100%;
        background: rgba(117, 192, 159, 0.6);
    }
}

.overlay-brown {
    &:before {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        content: '';
        width: 100%;
        height: 100%;
        background: rgba(172, 133, 123, 0.8);
    }
}

.overlay-lila {
    &:before {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        content: '';
        width: 100%;
        height: 100%;
        background: rgba(130, 114, 157, 0.8);
    }
}
.two-columns {
    padding: 7rem 0;
    -webkit-column-count: 2;
    column-count: 2;

    @include breakpoint(sm) {
        padding: 3rem 0;
        -webkit-column-count: 1;
        column-count: 1;
    }
}

.two-columns-manual {
    padding: 7rem 0;

    .column-left {
        float: left;
        width: 50%;
        padding: 0 2rem;
    }

    .column-right {
        float: right;
        width: 50%;
        padding: 0 2rem;
    }

    &:after {
        clear: both;
    }

    @include breakpoint(sm) {
        padding: 3rem 0;

        .column-left, .column-right {
            float: none;
            width: 100%;
            padding: 0;
        }
    }
}
.hidden {
    display: none;
    opacity: 0;
}
