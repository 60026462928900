#footer {
    @extend .section-vertical-padding-small;
    @extend .section-horizontal-padding;
    background: #1a1d26;
    color: #acb3c2;
}

// Sticky Footer solution
body.footer-sticky {
    min-height: 100%;
    display: flex;
    flex-direction: column;

    .body-wrapper {
        flex: 1;
    }
}
