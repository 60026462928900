.sponsors-container {
    margin: 5rem 0;

    .sponsor {
        position: relative;
        min-height: 165px;
        margin-bottom: 1rem;

        @include breakpoint(md) {
            margin: 1.5rem 0;
        }

        .sponsor-front,
        .sponsor-back {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            backface-visibility: hidden;
            transition: transform 0.5s;
        }

        .sponsor-front {
            text-align: center;
        }

        .sponsor-back {
            background: $primary-color;
            color: transparentize(#ffffff, 0.35);
            transform: rotateY(-180deg);

            .sponsor-back-content {
                display: inline-block;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: 2.5rem 2rem;
                padding: 1rem;
                text-align: left;

                .sponsor-title {
                    font-size: 2rem;
                    line-height: 1.25;
                    color: #ffffff;
                }

                .sponsor-description {
                    margin-bottom: 1rem;
                }

                .sponsor-link {
                    color: #ffffff;

                    &:hover {
                        color: transparentize(#ffffff, 0.35);
                    }
                }
            }
        }

        &:hover {
            .sponsor-front {
                transform: rotateY(-180deg);
            }

            .sponsor-back {
                transform: rotateY(0);
            }
        }
    }
}
