#header {
    @extend .section-horizontal-padding;
    position: absolute;
    top: 0;
    z-index: 5;
    padding: 2.8rem 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.6);
    color: #fff;

    // Fixed Header solution
    .header-fixed & {
        position: fixed;
        top: 0;
        z-index: 200;

        ~ #body {
            margin-top: 6rem;
        }
    }
}
