.page-header {
    position: relative;
    background-size: cover;
    background-position-x: 50%;
    overflow: hidden;
    color: #ffffff;

    &:before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: transparent url("../images/overlays/01.png") center center repeat;
        opacity: 0.5;
        z-index: 1;
    }

    .header-video-background {
        .header-video-wrapper {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            pointer-events: none;

            .header-video {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 450%;
                pointer-events: none;
            }
        }

        @media (min-aspect-ratio: 16/9) {
            .header-video-wrapper {
                height: 300%;
                top: -150%;

                @include breakpoint(sm) {
                    top: -175%;
                }
            }
        }

        @media (max-aspect-ratio: 16/9) {
            .header-video-wrapper {
                width: 300%;
                left: -100%;
                top: -150%;

                @include breakpoint(sm) {
                    top: -175%;
                }
            }
        }
    }

    .page-header-content {
        position: relative;
        z-index: 2;
        padding: 150px 0 60px;

        .page-header-uppertitle {
            font-style: italic;
            font-style: italic;
            @include responsive-font($responsive-html-font-size + 0.85, 25px, false, false);
        }

        .page-header-title {
            line-height: 1.1;
            text-transform: uppercase;
            font-weight: 300;
            line-height: 1.2;
            @include responsive-font($responsive-html-font-size + 4.5, 35px, false, false);

            @include breakpoint(md) {
                font-size: 3.5rem;
            }
        }

        .page-header-subtitle {
           font-weight: 700;
                letter-spacing: 3px;
                @include responsive-font($responsive-html-font-size + 0.5, 20px, false, false);


            @include breakpoint(xl) {
                margin-top: 1rem;
            }
        }
    }
}
