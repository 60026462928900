.testimonials-container {
    margin-top: 5rem;

    .testimonial {
        @include breakpoint(md) {
            &:not(:first-child) {
                margin: 4rem 0 0;
            }
        }

        .testimonial-wrapper {
            position: relative;
            margin: 1rem;
            padding: 1rem;
            border: 1px solid rgba(0, 0, 0, 0.1);

            .testimonial-avatar {
                position: absolute;
                top: -4.5rem;
                left: 0;
                margin: 0.5rem 0;
                width: 100%;
                min-width: 150px;

                img {
                    border-radius: 50%;
                    border: 5px solid #fff;
                    width: 75px;
                    height: 75px;
                }
            }

            .testimonial-text {
                padding-top: 4.5rem;
            }

            .testimonial-name {
                font-size: $font-size - 0.1;
                color: transparentize($body-font-color, 0.35);
                padding: 1.5rem 0 0.5rem;
            }

            .testimonial-social {
                display: block;

                .testimonial-social-icon {
                    display: inline-block;
                    padding: 10px 14px;
                    text-align: center;
                    font-size: 1.75rem;

                    & [class*="facebook"] {
                        color: #3B5998;

                        &:hover {
                            color: darken(#3B5998, 10%);
                        }
                    }

                    & [class*="google-plus"] {
                        color: #DB4437;

                        &:hover {
                            color: darken(#DB4437, 10%);
                        }
                    }

                    & [class*="twitter"] {
                        color: #3B8ACA;

                        &:hover {
                            color: darken(#3B8ACA, 10%);
                        }
                    }

                    & [class*="instagram"] {
                        color: #464646;

                        &:hover {
                            color: darken(#464646, 10%);
                        }
                    }
                }
            }
        }
    }
}
