.infocols-container {
    color: #ffffff;
    font-weight: 400;

    .infocols-column {
        &:not(:last-child) {
            border-right: 1px solid transparentize($body-font-color, 0.75);

            @include breakpoint(sm) {
                border-right: none;
                border-bottom: 1px solid transparentize($body-font-color, 0.75);
            }
        }

        &:not(:first-child) {
            padding-left: 3rem;

            @include breakpoint(sm) {
                padding-top: 2rem;
                padding-left: 0;
            }
        }

        .infocols-column-title {
            h6 {
                letter-spacing: 1px;
                font-weight: 600;
            }
        }

        .infocols-html-content {
            margin-top: 2rem;
            line-height: 2;
        }

        .infocols-column-list {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin-left: 0;
            list-style-type: none;

            .infocols-column-list-item {
                width: 50%;

                a {
                    color: $body-font-color;

                    &:hover {
                        color: #ffffff;
                    }
                }
            }
        }
    }
}
