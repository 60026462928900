html {
    height: 100%;
}

img {
    width: auto\9;
    height: auto;
    max-width: 100%;
    display: inline-block;
    vertical-align: middle;
    border: 0;
    -ms-interpolation-mode: bicubic;
}

// Section Horizontal Paddings
.section-horizontal-padding {
    padding-left: 10%;
    padding-right: 10%;

    @include breakpoint(xs) {
        padding-left: 5%;
        padding-right: 5%;
    }
}

// Section Vertical Paddings
.section-vertical-padding {
    padding-top: 4vw;
    padding-bottom: 4vw;

    @include breakpoint(xs) {
        padding-top: 3rem;
        padding-bottom: 3rem;
    }
}

// Section Small Vertical Paddings
.section-vertical-padding-small {
    padding-top: 2vw;
    padding-bottom: 2vw;

    @include breakpoint(xs) {
        padding-top: 3rem;
        padding-bottom: 3rem;
    }
}

// Error configuration
#error {
    text-align: center;
    position: relative;
    margin-top: 5rem;

    .icon {
        font-size: ($html-font-size * 5);
    }
}
