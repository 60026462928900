h1 {
    font-size: ($html-font-size * 6);
}

h3 {
    &.title {
        padding: 1.5rem 0;
        color: #2a2a2a;
    }
}

p {
    margin-bottom: 3rem;
    line-height: 1.8;
}
ul, ol {
    @include responsive-font($responsive-html-font-size, 16px, false, false);
}

.notices {
    color: #fff;
    background-color: #194F7A;
    padding: 5px;

    p {
        margin: 0;
    }

    &.success {
        background: #72AA23;
    }

    &.error {
        background: #AA2523;
    }
}
