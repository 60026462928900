.kurse-page {
    position: relative;
    background-size: contain;
    background-repeat: no-repeat;
}

#courses {
    .course-group-header {
        margin-bottom: 4rem;

        &:not(:first-child) {
            margin-top: 4rem;
        }

        .course-group-description {
            font-size: $font-size + 0.35;
        }
    }

    .course-block {
        &:not(:last-child) {
            border-bottom: 1px solid $border-color;
        }

        // Single Course
        .course-wrapper {
            display: flex;
            padding: 2rem 0;
            min-height: 200px;

            @include breakpoint(sm) {
                flex-direction: column;
            }

            .course-left-block {
                flex: 1 1 20%;
                padding: 3rem 2rem;
                font-size: $font-size + 0.5;
                font-weight: bold;
                color: #ffffff;

                .course-name {
                    text-transform: uppercase;
                }
            }

            .course-middle-block {
                flex: 4 2 60%;
                padding: 3rem;
                background: #ffffff;
                font-size: $font-size + 0.25;

                .course-description {
                    &, & p {
                        margin: 0;
                        font-style: italic;
                        line-height: 2;
                    }
                }
            }

            .course-right-block {
                flex: 1 1 20%;
                padding: 3rem 2rem;
                background: #ffffff;
                text-align: center;

                .course-price {
                    margin-bottom: 3rem;
                    font-size: $font-size + 1.5;

                    .course-price-details {
                        font-size: $font-size;
                    }
                }

                .course-link {
                    padding: 1.25rem 2rem;
                    background: $body-font-color;
                    color: #ffffff;

                    &:hover {
                        background: $primary-color;
                    }
                }
            }
        }
    }

    .course-iframe {
        margin-bottom: 1rem;
    }
}
