%item-container {
    position: relative;
    display: block;
    color: inherit;
    font-size: $font-size + 0.2;
    padding: 4px 8px;
    border-radius: 2px;
    white-space: normal;
    word-wrap: break-word;
    transition: all 0.2s ease;
}

// Navigation
ul.nav {
    margin: 0.825rem;
    padding: 1rem;
    list-style: none;
    @include clearfix;

    // Item Container
    .nav-item-container {
        color: $gray-color-light;

        .nav-item-content {
            display: inline-block;
            vertical-align: middle;
            cursor: pointer;
            transition: box-shadow 0.35s ease;

            .nav-item-icon {
                margin-right: 2px;
            }

            .nav-item-subtitle {
                display: block;
                font-size: $font-size - 0.15;
            }
        }

        .nav-item-parent-indicator {
            position: absolute;
            right: 0.75rem;
            display: inline-block;
            line-height: normal;
            vertical-align: top;

            &:after {
                display: inline-block;
                cursor: pointer;
                opacity: 0.75;
                font-family: FontAwesome, serif;
                content: "\f105";
                text-align: right;
                width: 1.75rem;
                height: 1.75rem;
                padding: 0.25rem;
            }
        }

        &:hover,
        &:focus {
            color: inherit;
            box-shadow: none;

            .nav-item-content {
                box-shadow: 0 2px 0 $gray-color-light;
            }
        }
    }

    // Top Level
    > li.nav-item {
        position: relative;
        display: block;
        margin: 0 0 0.75rem 0;

        &:focus {
            outline: 0;
        }

        > .nav-item-container {
            @extend %item-container;
        }

        &.active > a {
            background: transparentize($gray-color-light, 0.8);
            font-weight: normal;

            &:hover,
            &:focus {
                color: inherit;
                background: none;
            }
        }
    }

    // Open dropdowns
    .open  {
        > .dropdown-menu {
            display: block;
        }

        > .nav-item-container {
            outline: 0;

            &,
            &:hover,
            &:focus {
                .nav-item-content {
                    box-shadow: 0 2px 0 $gray-color-light;
                }
            }
        }
    }

    .dropdown {
        position: relative;
        display: block;
    }

    .dropdown-menu {
        position: absolute;
        top: -1.3rem;
        z-index: 20;
        display: none;
        float: left;
        min-width: 180px;
        padding: 5px 0;
        margin: 2px 0 0;
        list-style: none;
        font-size: $font-size;
        text-align: left;
        background-clip: padding-box;
        border-radius: 4px;
        background: #000000;
        color: $gray-color-light;
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.25);

        @include breakpoint(sm) {
            position: static;
            background: transparent;
            box-shadow: none;
            width: 100% !important;
        }

        &:before {
            content: "";
            position: absolute;
            display: block;
            top: -5px;
            left: 0;
            right: 0;
            bottom: 0;
            border-top: 5px solid transparent;
            z-index: -1;
        }

        &:after {
            content: "";
            position: absolute;
            display: block;
            top: 0;
            left: -20px;
            right: -20px;
            bottom: 0;
            border-left: 20px solid transparent;
            border-right: 20px solid transparent;
            z-index: -1;
        }

        // Links within the dropdown menu
        > li.nav-item {
            position: relative;
            margin: 0.5rem 0.5rem 0.685rem 0.5rem;
            padding: 0 0 0.2345rem 0;
            border: 1px solid transparent;

            > .nav-item-container {
                @extend %item-container;
                padding: 0.25rem 0.5rem;
                clear: both;
                font-weight: normal;
                white-space: nowrap;

                // Item Content
                .nav-item-content {
                    vertical-align: top;
                }

                // Hover/Focus state
                &:hover,
                &:focus {
                    .nav-item-content {
                        box-shadow: 0 2px 0 $gray-color-light;
                    }
                }
            }
        }

        // Columns
        .columns {
            margin: 0.25rem 0.5rem;

            .column {
                flex: 0 1 auto;
                padding: 0.25rem 0.5rem;

                @include breakpoint(sm) {
                    flex: 0 1 100%;
                }
            }
        }

        // Dropdown Directions
        &.dropdown-menu-left {
            right: 100%;
            left: auto;
        }

        &.dropdown-menu-right {
            left: 100%;
            right: auto;
        }

        &.dropdown-menu-center {
            top: 100%;
            left: 0;
            right: 0;
        }
    }

    // Backdrop to catch body clicks on mobile, etc.
    .dropdown-backdrop {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        z-index: 10;
    }
}
