#main-content {
    @extend .section-vertical-padding-small;
    @extend .section-horizontal-padding;
    background-repeat: no-repeat;
    background-size: cover;
}

.module-section {
    background-repeat: no-repeat;
    background-size: cover;
}
